import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
// News
import NewsView from "@/views/News/NewsView.vue";
import NewsInfo from "@/views/News/NewsInfo.vue";
// Projects
import ProjectsView from "@/views/Projects/ProjectsView.vue";
import ProjectInfo from "@/views/Projects/ProjectInfo.vue";
// Login
import LoginView from "@/views/LoginView.vue";
// Register
import RegisterView from "@/views/RegisterView.vue";

// Admin Dashboard
import AdminHome from "@/views/AdminDashboard/AdminHome.vue";
// Admin users
import UsersView from "@/views/AdminDashboard/Users/UsersView.vue";
// Admin News
import AdminNews from "@/views/AdminDashboard/News/NewsView.vue";
// Admin add News
import AddNews from "@/views/AdminDashboard/News/AddNews.vue";
// Admin add news files
import AddFiles from "@/views/AdminDashboard/News/AddFiles.vue";
// Admin products
import AdminProducts from "@/views/AdminDashboard/Products/AdminProducts.vue";
// Admin add products
import AddProduct from "@/views/AdminDashboard/Products/AddProduct.vue";
// Admin edit products
import EditProduct from "@/views/AdminDashboard/Products/EditProduct.vue";
// Admin Services
import ServicesViewVue from "@/views/AdminDashboard/Services/ServicesView.vue";
// Admin Add Service
import AddServiceVue from "@/views/AdminDashboard/Services/AddService.vue";
// Admin Types
import TypesViewVue from "@/views/AdminDashboard/Types/TypesView.vue";
// Admin Add type
import AddTypeVue from "@/views/AdminDashboard/Types/AddType.vue";
// Admin Plans
import PlansViewVue from "@/views/AdminDashboard/Plans/PlansView.vue";
// Admin Add plan
import AddPlanVue from "@/views/AdminDashboard/Plans/AddPlan.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // News
  {
    path: "/news",
    name: "News",
    component: NewsView,
  },
  {
    path: "/news-info",
    name: "NewsInfo",
    component: NewsInfo,
  },
  // Projects
  {
    path: "/projects",
    name: "ProjectsView",
    component: ProjectsView,
  },
  {
    path: "/project-info",
    name: "ProjectInfo",
    component: ProjectInfo,
  },
  // login
  {
    path: "/login",
    name: "LoginView",
    component: LoginView,
  },
  // register
  {
    path: "/make-appointment",
    name: "RegisterView",
    component: RegisterView,
  },
  // Admin dashboard
  {
    path: "/admin-home",
    name: "AdminHome",
    component: AdminHome,
  },
  // Admin users
  {
    path: "/admin-users",
    name: "UsersView",
    component: UsersView,
  },
  // Admin News
  {
    path: "/admin-news",
    name: "AdminNews",
    component: AdminNews,
  },
  // Admin add News
  {
    path: "/admin-add-news",
    name: "AddNews",
    component: AddNews,
  },
  // Admin add News files
  {
    path: "/admin-add-files",
    name: "AddFiles",
    component: AddFiles,
  },
  // Admin products
  {
    path: "/admin-products",
    name: "AdminProducts",
    component: AdminProducts,
  },
  // Admin add products
  {
    path: "/admin-add-product",
    name: "AddProduct",
    component: AddProduct,
  },
  // Admin edit products
  {
    path: "/admin-edit-product",
    name: "EditProduct",
    component: EditProduct,
  },
  // Admin Services
  {
    path: "/admin-services",
    name: "ServicesView",
    component: ServicesViewVue,
  },
  // Admin add service
  {
    path: "/admin-add-services",
    name: "AddService",
    component: AddServiceVue,
  },
  // Admin Types
  {
    path: "/admin-types",
    name: "TypesView",
    component: TypesViewVue,
  },
  // Admin add Types
  {
    path: "/admin-add-types",
    name: "AddTypeView",
    component: AddTypeVue,
  },
  // Admin plans
  {
    path: "/admin-plans",
    name: "PlansView",
    component: PlansViewVue,
  },
  // Admin add plan
  {
    path: "/admin-add-plans",
    name: "AddPlan",
    component: AddPlanVue,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
