<template>
  <head>
    <title>
      {{ id == 0 ? $t("dashboard.AddType") : $t("dashboard.EditType") }}
    </title>
  </head>
  <div class="add-type">
    <SideBar>
      <div
        class="container bg-light h-100 d-flex justify-content-center align-items-start py-5"
        style="overflow-y: scroll"
      >
        <CardComponent
          :title="id == 0 ? $t('dashboard.AddType') : $t('dashboard.EditType')"
        >
          <form
            class="w-100 d-flex justify-content-center align-items-center"
            v-on:submit.prevent="id == 0 ? addTypes() : editTypes()"
          >
            <div class="d-block w-100">
              <div class="my-3">
                <input
                  type="text"
                  v-model="nameAr"
                  :placeholder="$t('dashboard.NameAr')"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                />
              </div>
              <div class="my-3">
                <input
                  type="text"
                  v-model="nameEn"
                  :placeholder="$t('dashboard.NameEn')"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                />
              </div>
              <div class="my-3">
                <button type="submit" class="add-btn">
                  {{ id == 0 ? $t("dashboard.add") : $t("dashboard.edit") }}
                </button>
              </div>
            </div>
          </form>
        </CardComponent>
      </div>
    </SideBar>
    <div class="popUp" :style="{ display: dis, zIndex: Zindex }">
      <div class="title">{{ $t("dashboard.loading") }}</div>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/AdminDashboard/SideBar.vue";
import CardComponent from "@/components/AdminDashboard/CardComponent.vue";
import router from "@/router";
import store from "@/store";
import axios from "axios";
export default {
  name: "add-news",
  data() {
    return {
      nameAr: "",
      nameEn: "",
      dis: "none",
      id: 0,
      Zindex: -3,
    };
  },
  components: {
    SideBar,
    CardComponent,
  },
  methods: {
    addTypes() {
      if (this.nameAr === "" || this.nameEn === "") {
        alert(
          this.$i18n.locale === "ar"
            ? "الرجاء تعبئة الحقول المطلوبة"
            : "Please fill all the required fields"
        );
        return;
      }

      const formData = new FormData();
      formData.append("name_ar", this.nameAr);
      formData.append("name_en", this.nameEn);

      this.dis = "block";
      this.Zindex = 10;

      const token = store.state.token;
      if (!token) {
        alert("Token is missing");
        this.dis = "none";
        this.Zindex = -3;
        return;
      }

      axios
        .post(store.state.server + "add-type", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            alert("تمت الإضافة بنجاح");
            this.dis = "none";
            this.Zindex = -3;
            router.push("/admin-types");
          }
        })
        .catch((error) => {
          console.log(error);
          this.dis = "none";
          this.Zindex = -3;
        });
    },
    editTypes() {
      if (this.nameAr === "" || this.nameEn === "") {
        alert(
          this.$i18n.locale === "ar"
            ? "الرجاء تعبئة الحقول المطلوبة"
            : "Please fill all the required fields"
        );
        return;
      }

      const formData = new FormData();
      formData.append("name_ar", this.nameAr);
      formData.append("name_en", this.nameEn);

      this.dis = "block";
      this.Zindex = 10;

      const token = store.state.token;
      if (!token) {
        alert("Token is missing");
        this.dis = "none";
        this.Zindex = -3;
        return;
      }

      axios
        .post(store.state.server + "update-type/" + this.id, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            alert("تمت التعديل بنجاح");
            this.dis = "none";
            this.Zindex = -3;
            router.push("/admin-types");
          }
        })
        .catch((error) => {
          console.log(error);
          this.dis = "none";
          this.Zindex = -3;
        });
    },
    getData() {
      axios
        .get(store.state.server + "get-type/" + this.id)
        .then((response) => {
          this.nameAr = response.data.name_ar;
          this.nameEn = response.data.name_en;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get("id") !== null) {
      this.id = urlParams.get("id");
      this.getData();
    }
  },
};
</script>

<style scoped>
.popUp {
  height: 100dvh;
  width: 100dvw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.3);
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 100dvw;
  font-size: 40px;
  font-weight: 700;
}
</style>
