<template>
  <head>
    <title>{{ $t("Navbar.Projects") }}</title>
  </head>
  <div class="projects-view">
    <div class="container p-2 hidden" v-in-view="'animate__slideInUp'">
      <div class="w-100">
        <!-- types -->
        <div
          class="container p-5 bg-light my-3 hidden"
          v-in-view="'animate__fadeInUp'"
          v-if="Object.keys(types).length > 0"
        >
          <h3>{{ $t("PressToFilterType") }}</h3>
          <div class="sections" v-if="Object.keys(types).length > 0">
            <div class="my-5" v-if="Object.keys(types).length > 0">
              <div class="row">
                <div
                  class="col-lg-3 mt-3"
                  v-for="(item, index) in types"
                  :key="index"
                >
                  <button
                    class="btn btn-outline-warning w-100"
                    @click="
                      type_id = item.id;
                      filterData();
                    "
                  >
                    {{ item.name_ar }}
                  </button>
                </div>
                <div class="col-lg-12 mt-5">
                  <button
                    class="btn btn-outline-danger w-100"
                    @click="
                      type_id = 0;
                      filterData();
                    "
                  >
                    {{ $t("ClearFilter") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-whitesmoke my-5">
        <div class="page-title pt-5">{{ $t("Navbar.Projects") }}</div>
        <div class="page-content my-4 pb-5">
          <ProjectCards
            class="m-3 hidden"
            v-in-view="'animate__backInUp'"
            v-for="(item, index) in filteredData"
            :key="index"
            :img="item.img"
            :id="item.id"
            :title="item.name_ar"
          ></ProjectCards>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectCards from "@/components/ProjectCard/ProjectCards.vue";
import store from "@/store";
import axios from "axios";

export default {
  name: "projects-view",
  data() {
    return {
      data: {
        0: {
          id: 1,
          name_ar: "1مشروع",
          img: require("@/assets/projects/1.jpg"),
          type_id: 1,
          project_plans: {
            0: {
              plan_id: 1,
              available: 1,
            },
            1: {
              plan_id: 2,
              available: 0,
            },
          },
        },
        1: {
          id: 2,
          name_ar: "2مشروع",
          img: require("@/assets/projects/1.jpg"),
          type_id: 2,
          project_plans: {
            0: {
              plan_id: 2,
              available: 1,
            },
            1: {
              plan_id: 3,
              available: 1,
            },
          },
        },
        2: {
          id: 1,
          name_ar: "3مشروع",
          img: require("@/assets/projects/1.jpg"),
          type_id: 3,
          project_plans: {
            0: {
              plan_id: 3,
              available: 1,
            },
            1: {
              plan_id: 4,
              available: 0,
            },
          },
        },
      },
      types: {
        0: {
          id: 1,
          name_ar: "نوع1",
        },
        1: {
          id: 2,
          name_ar: "نوع2",
        },
        2: {
          id: 3,
          name_ar: "نوع3",
        },
      },

      old_data: {},
      type_id: 0,
      screen_width: screen.width,
      filteredData: [], // To store the filtered results
    };
  },

  components: {
    ProjectCards,
  },
  methods: {
    hideLoadingScreen() {
      store.commit("hideLoadingScreen");
    },
    getData() {
      axios
        .get(store.state.server + "news-index")
        .then((response) => {
          this.old_data = response.data;
          this.hideLoadingScreen();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterData() {
      // Destructure plan_id and type_id for filtering
      const { type_id, data } = this;

      // Convert the data object to an array for easier filtering
      const dataArray = Object.values(data);

      this.filteredData = dataArray.filter((project) => {
        const matchesType = type_id === 0 || project.type_id === type_id; // 0 means "all types"
        return matchesType;
      });
    },
  },
  directives: {
    inView: {
      mounted(el, binding) {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                el.classList.add("animate__animated", binding.value);
                el.classList.remove("hidden");
                observer.unobserve(el); // Stop observing
              }
            });
          },
          { threshold: 0.1 } // Trigger when 10% of the element is visible
        );
        observer.observe(el);
      },
    },
  },
  mounted() {
    this.getData();
    this.filterData();
  },
};
</script>

<style>
.page-title {
  padding: 10px;
  font-size: 40px;
  color: #c39a3e;
}

.page-content {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
}

.hidden {
  visibility: hidden;
}

.image-overlay {
  position: relative;
}

.overlay {
  background: rgba(0, 0, 0, 0.3);
  color: whitesmoke;
  font-weight: 700;
  font-size: 30px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 0;
  cursor: pointer;
}

.swiper-image {
  height: 400px;
  width: fit-content;
  cursor: pointer;
}

@media screen and (max-width: 1025px) {
  .page-content {
    grid-template-columns: auto;
  }
  .swiper-image {
    height: 180px;
  }
  .overlay {
    font-size: 16px;
  }
}
</style>
