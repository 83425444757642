<template>
  <head>
    <title>{{ $t("dashboard.AddNews") }}</title>
  </head>
  <div class="add-news">
    <SideBar>
      <div
        class="container bg-light h-100 d-flex justify-content-center align-items-start py-5"
        style="overflow-y: scroll"
      >
        <CardComponent :title="$t('dashboard.AddNews')">
          <form
            class="w-100 d-flex justify-content-center align-items-center"
            v-on:submit.prevent="addNews()"
          >
            <div class="d-block w-100">
              <div class="my-3">
                <input
                  type="text"
                  v-model="name"
                  :placeholder="$t('dashboard.name')"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                />
              </div>
              <div class="my-3">
                <textarea
                  class="my-inp w-50"
                  :placeholder="$t('dashboard.content')"
                  v-model="text"
                  style="border: 1px solid black; height: 100px"
                ></textarea>
              </div>
              <div class="my-3">
                <input
                  type="text"
                  v-model="link"
                  :placeholder="$t('dashboard.link')"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                />
              </div>
              <div class="my-3">
                <h3>{{ $t("dashboard.cover") }}</h3>
                <input
                  type="file"
                  id="src"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                  accept="image/*"
                />
              </div>
              <div class="my-3">
                <h3>{{ $t("dashboard.newsImages") }}</h3>
                <input
                  type="file"
                  id="images"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                  accept="image/*"
                  multiple
                />
              </div>
              <div class="my-3">
                <h3>{{ $t("dashboard.videos") }}</h3>
                <input
                  type="file"
                  id="videos"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                  accept="video/*"
                  multiple
                />
              </div>
              <!-- <div class="my-3">
                <h3>الشهادة</h3>
                <input
                  type="file"
                  id="certificate"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                  accept="image/*"
                  multiple
                />
              </div> -->
              <div class="my-3">
                <button type="submit" class="add-btn">
                  {{ $t("dashboard.add") }}
                </button>
              </div>
            </div>
          </form>
        </CardComponent>
      </div>
    </SideBar>
    <div class="popUp" :style="{ display: dis, zIndex: Zindex }">
      <div class="title">{{ $t("dashboard.loading") }}</div>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/AdminDashboard/SideBar.vue";
import CardComponent from "@/components/AdminDashboard/CardComponent.vue";
import router from "@/router";
import store from "@/store";
import axios from "axios";
export default {
  name: "add-news",
  data() {
    return {
      name: "",
      text: "",
      link: "",
      dis: "none",
      Zindex: -3,
    };
  },
  components: {
    SideBar,
    CardComponent,
  },
  methods: {
    addNews() {
      if (this.name === "" || this.text === "") {
        alert(
          this.$i18n.locale === "ar"
            ? "الرجاء تعبئة الحقول المطلوبة"
            : "Please fill all the required fields"
        );
        return;
      }

      const formData = new FormData();
      const imgFile = document.querySelector("#src");
      const imgFiles = document.querySelector("#images");
      const imgsData = imgFiles.files;
      const videoFiles = document.querySelector("#videos");
      const videosData = videoFiles.files;
      // const certificateFiles = document.querySelector("#certificate");
      // const certificatesData = certificateFiles.files;
      if (imgFile.length > 0) {
        alert(
          this.$i18n.locale === "ar"
            ? "الرجاء إضافة صورة غلاف"
            : "Please add cover photo"
        );
        return;
      }
      formData.append("image", imgFile.files[0]);
      formData.append("name", this.name);
      formData.append("text", this.text);
      formData.append("link", this.link);

      if (imgsData.length > 0) {
        for (let i = 0; i < imgsData.length; i++) {
          formData.append(`images[${i}]`, imgsData[i]);
        }
      }

      if (videosData.length > 0) {
        for (let i = 0; i < videosData.length; i++) {
          formData.append(`videos[${i}]`, videosData[i]);
        }
      }

      // if (certificatesData.length > 0) {
      //   for (let i = 0; i < certificatesData.length; i++) {
      //     formData.append(`certificates[${i}]`, certificatesData[i]);
      //   }
      // }

      this.dis = "block";
      this.Zindex = 10;

      const token = store.state.token;
      if (!token) {
        alert("Token is missing");
        this.dis = "none";
        this.Zindex = -3;
        return;
      }

      axios
        .post(store.state.server + "news-store", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            alert("تمت الإضافة بنجاح");
            this.dis = "none";
            this.Zindex = -3;
            router.push("/admin-news");
          }
        })
        .catch((error) => {
          console.log(error);
          this.dis = "none";
          this.Zindex = -3;
        });
    },
  },
};
</script>

<style scoped>
.popUp {
  height: 100dvh;
  width: 100dvw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.3);
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 100dvw;
  font-size: 40px;
  font-weight: 700;
}
</style>
