<template>
  <head>
    <title>إضافة ملفات</title>
  </head>
  <div class="add-files">
    <SideBar>
      <div
        class="container bg-light h-100 d-flex justify-content-center align-items-start py-5"
        style="overflow-y: scroll"
      >
        <CardComponent title="إضافة ملفات">
          <form
            class="w-100 d-flex justify-content-center align-items-center"
            v-on:submit.prevent="addNews()"
          >
            <div class="d-block w-100">
              <div class="my-3">
                <input
                  type="file"
                  id="certificate"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                  accept=".xlsx,.xls"
                />
              </div>
              <div class="my-3">
                <button type="submit" class="add-btn">إضافة</button>
              </div>
            </div>
          </form>
        </CardComponent>
      </div>
    </SideBar>
    <div class="popUp" :style="{ display: dis, zIndex: Zindex }">
      <div class="title">جاري التحميل...</div>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/AdminDashboard/SideBar.vue";
import CardComponent from "@/components/AdminDashboard/CardComponent.vue";
import router from "@/router";
import store from "@/store";
import axios from "axios";
export default {
  name: "add-files",
  data() {
    return {
      dis: "none",
      Zindex: -3,
    };
  },
  components: {
    SideBar,
    CardComponent,
  },
  methods: {
    addNews() {
      const formData = new FormData();
      const certificateFiles = document.querySelector("#certificate");
      const certificatesData = certificateFiles.files;

      if (certificatesData.length > 0) {
        formData.append(`file`, certificatesData[0]);
      } else {
        alert("الرجاء إضافة ملفات");
        return;
      }

      this.dis = "block";
      this.Zindex = 10;

      const token = store.state.token;
      if (!token) {
        alert("Token is missing");
        this.dis = "none";
        this.Zindex = -3;
        return;
      }

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const id = urlParams.get("id");
      axios
        .post(store.state.server + "news-file-store/" + id, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            alert("تمت الإضافة بنجاح");
            this.dis = "none";
            this.Zindex = -3;
            router.push("/admin-news");
          }
        })
        .catch((error) => {
          console.log(error);
          this.dis = "none";
          this.Zindex = -3;
        });
    },
  },
};
</script>

<style scoped>
.popUp {
  height: 100dvh;
  width: 100dvw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.3);
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 100dvw;
  font-size: 40px;
  font-weight: 700;
}
</style>
