<template>
  <head>
    <title>تعديل المنتج منتج</title>
  </head>
  <div class="add-product">
    <SideBar>
      <div
        class="container bg-light h-100 d-flex justify-content-center align-items-start py-5"
        style="overflow-y: scroll"
      >
        <CardComponent title="تعديل منتج">
          <form
            class="w-100 d-flex justify-content-center align-items-center"
            v-on:submit.prevent="EditProduct()"
          >
            <div class="d-block w-100">
              <div class="my-3">
                <input
                  type="text"
                  v-model="name"
                  placeholder="اسم المنتج"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                />
              </div>
              <!-- <div class="my-3">
                <select
                  @change="getIDSub()"
                  id="sub_categories"
                  v-model="sub_category_name"
                  class="my-inp"
                  style="border: 1px solid black"
                >
                  <option value="اختر نوع المنتج" disabled selected>
                    اختر نوع المنتج
                  </option>
                  <option
                    v-for="(item, index) in sub_categories"
                    :key="index"
                    :value="item.name"
                    :id="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div> -->
              <div
                class="my-3 d-flex justify-content-center align-items-center"
              >
                <h3 class="me-1">هل المنتج متاح؟</h3>
                <input type="checkbox" v-model="is_available" class="my-inp" />
              </div>
              <div class="my-3">
                <input
                  type="number"
                  v-model="price"
                  placeholder="سعر المنتج"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                  min="1"
                  v-on:input="is_discount ? ChangePrice() : ''"
                />
              </div>
              <div
                class="my-3 d-flex justify-content-center align-items-center"
              >
                <h3 class="me-1">هل يوجد خصم؟</h3>
                <input type="checkbox" v-model="is_discount" class="my-inp" />
              </div>
              <div class="my-3" v-if="is_discount">
                <select
                  @change="getID()"
                  id="discounts"
                  v-model="discount_name"
                  class="my-inp"
                  style="border: 1px solid black"
                >
                  <option value="اختر خصم" disabled selected>اختر خصم</option>
                  <option
                    v-for="(item, index) in discounts"
                    :key="index"
                    :value="item.name"
                    :id="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="my-3" v-if="is_discount">
                <input
                  type="number"
                  v-model="discount_price"
                  placeholder="سعر المنتج بعد الخصم"
                  class="my-inp w-50"
                  style="border: 1px solid black; pointer-events: none"
                  min="1"
                />
              </div>
              <div class="my-3">
                <textarea
                  class="my-inp w-50"
                  placeholder="الوصف"
                  v-model="description"
                  style="border: 1px solid black; height: 100px"
                ></textarea>
              </div>
              <div class="my-3">
                <input
                  type="number"
                  v-model="weight"
                  placeholder="وزن المنتج"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                  min="1"
                />
              </div>
              <div class="my-3">
                <input
                  type="number"
                  v-model="width"
                  placeholder="عرض المنتج"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                  min="1"
                />
              </div>
              <div class="my-3">
                <input
                  type="number"
                  v-model="height"
                  placeholder="ارتفاع المنتج"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                  min="1"
                />
              </div>
              <div class="my-3">
                <input
                  type="text"
                  v-model="dimension"
                  placeholder="ابعاد المنتج"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                  min="1"
                />
              </div>
              <!-- <div class="my-3">
                <h3>اختر المواد</h3>
                <div class="row row-cols-auto">
                  <div
                    class="col-4 d-flex justify-content-center align-items-center"
                    v-for="(item, index) in materials_data"
                    :key="index"
                  >
                    <input
                      type="checkbox"
                      class="ms-3"
                      @click="AddRemoveMaterial(item.id)"
                    />{{ item.name }}
                  </div>
                </div>
              </div>
              <div class="my-3">
                <h3>اختر الالوان</h3>
                <div class="row row-cols-auto">
                  <div
                    class="col-4 d-flex justify-content-center align-items-center"
                    v-for="(item, index) in colors_data"
                    :key="index"
                  >
                    <input
                      type="checkbox"
                      class="ms-3"
                      @click="AddRemoveColors(item.id)"
                    />
                    <div
                      class="color_circle"
                      :style="{ background: item.name }"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="my-3">
                <h3>صورة المنتج الرئيسية</h3>
                <input
                  type="file"
                  id="src"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                  accept="image/*"
                />
              </div>
              <div class="my-3">
                <h3>صور المنتج</h3>
                <input
                  type="file"
                  id="images"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                  accept="image/*"
                  multiple
                />
              </div>
              <div class="my-3">
                <h3>ملف 3D</h3>
                <input
                  type="file"
                  id="d_image"
                  class="my-inp w-50"
                  style="border: 1px solid black"
                />
              </div> -->
              <div class="my-3">
                <button type="submit" class="add-btn">إضافة</button>
              </div>
            </div>
          </form>
        </CardComponent>
      </div>
    </SideBar>
    <div class="popUp" :style="{ display: dis, zIndex: Zindex }">
      <div class="title">جاري التحميل...</div>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/AdminDashboard/SideBar.vue";
import CardComponent from "@/components/AdminDashboard/CardComponent.vue";
import router from "@/router";
import store from "@/store";
import axios from "axios";
export default {
  name: "add-product",
  data() {
    return {
      id: "",
      name: "",
      description: "",
      is_available: 0,
      price: "",
      discount_id: "",
      discount_price: "",
      weight: "",
      height: "",
      width: "",
      //   sub_category_id: "",
      dimension: "",
      //   materials: [],
      //   colors: [],

      discounts: [],
      //   sub_categories: [],
      //   materials_data: [],
      //   colors_data: [],
      discount_name: "اختر خصم",
      //   sub_category_name: "اختر نوع المنتج",
      is_discount: 0,
      discount_value: "",
      dis: "none",
      Zindex: -3,
    };
  },
  components: {
    SideBar,
    CardComponent,
  },
  methods: {
    EditProduct() {
      if (
        this.name === "" ||
        this.description === "" ||
        this.price === "" ||
        this.weight === "" ||
        this.height === "" ||
        this.width === "" ||
        // this.sub_category_id === "" ||
        this.dimension === "" ||
        // this.colors.length === 0 ||
        // this.materials.length === 0 ||
        ((this.is_discount !== 0 || this.is_discount == true) &&
          this.discount_id === "")
      ) {
        alert("الرجاء تعبئة الحقول المطلوبة");
        return;
      }

      const formData = new FormData();
      //   const imgFile = document.querySelector("#src");
      //   const imgFiles = document.querySelector("#images");
      //   const imgsData = imgFiles.files;
      //   const d_image = document.querySelector("#d_image");

      //   if (imgFile.files.length == 0) {
      //     alert("قم بإدخال صورة المنتج");
      //     return;
      //   }

      //   if (d_image.files.length == 0) {
      //     alert("قم بإدخال ملف 3D المنتج");
      //     return;
      //   }

      //   formData.append("src", imgFile.files[0]);
      //   formData.append("d_image", d_image.files[0]);
      formData.append("name", this.name);
      formData.append("description", this.description);
      formData.append("isAvailable", this.is_available == true ? 1 : 0);
      formData.append("price", this.price);
      formData.append("weight", this.weight);
      formData.append("height", this.height);
      formData.append("width", this.width);
      // formData.append("sub_category_id", this.sub_category_id);
      formData.append("dimension", this.dimension);
      formData.append("discount_id", this.discount_id);
      formData.append("discount_price", this.discount_price);

      //   if (imgsData.length > 0) {
      //     for (let i = 0; i < imgsData.length; i++) {
      //       formData.append(`images[${i}]`, imgsData[i]);
      //     }
      //   } else {
      //     alert("قم بإدخال صور المنتج");
      //     return;
      //   }

      //   for (let i = 0; i < this.materials.length; i++) {
      //     formData.append(`materials[${i}]`, this.materials[i]);
      //   }

      //   for (let i = 0; i < this.colors.length; i++) {
      //     formData.append(`colors[${i}]`, this.colors[i]);
      //   }

      this.dis = "block";
      this.Zindex = 10;

      const token = store.state.token;
      if (!token) {
        alert("Token is missing");
        this.dis = "none";
        this.Zindex = -3;
        return;
      }

      axios
        .post(store.state.server + "item-update/" + this.id, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            alert("تم التعديل بنجاح");
            this.dis = "none";
            this.Zindex = -3;
            router.push("/admin-products");
          }
        })
        .catch((error) => {
          console.log(error);
          this.dis = "none";
          this.Zindex = -3;
        });
    },
    getData() {
      axios
        .get(store.state.server + "index-dis")
        .then((response) => {
          this.discounts = response.data;
          // this.getSub();
          this.getItem();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSub() {
      axios
        .get(store.state.server + "sub-category-index")
        .then((response) => {
          this.sub_categories = response.data;
          this.getMat();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMat() {
      axios
        .get(store.state.server + "material-index")
        .then((response) => {
          this.materials_data = response.data;
          this.getColors();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getColors() {
      axios
        .get(store.state.server + "color-index")
        .then((response) => {
          this.colors_data = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getID() {
      const element = document.getElementById("discounts");
      for (let i = 0; i < element.length; i++) {
        if (element[i].value == this.discount_name) {
          this.discount_id = element[i].id;
          this.discount_value = this.discounts[i - 1].value;
          this.ChangePrice();
          break;
        }
      }
    },
    getIDSub() {
      const element = document.getElementById("sub_categories");
      for (let i = 0; i < element.length; i++) {
        if (element[i].value == this.sub_category_name) {
          this.sub_category_id = element[i].id;
          break;
        }
      }
    },
    ChangePrice() {
      if (this.discount_value == "") {
        alert("اختر خصم من فضلك");
        return;
      }
      this.discount_price =
        this.price - (this.price * this.discount_value) / 100;
    },
    AddRemoveMaterial(id) {
      const index = this.materials.indexOf(id);
      if (index > -1) {
        this.materials.splice(index, 1); // Remove the element at the specified index
      } else {
        this.materials.push(id); // Add the element if it's not in the array
      }
    },
    AddRemoveColors(id) {
      const index = this.colors.indexOf(id);
      if (index > -1) {
        this.colors.splice(index, 1); // Remove the element at the specified index
      } else {
        this.colors.push(id); // Add the element if it's not in the array
      }
    },
    getItem() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const id = urlParams.get("id");
      axios
        .get(store.state.server + "show-item/" + id)
        .then((response) => {
          this.id = id;
          this.name = response.data.name;
          this.description = response.data.description;
          this.is_available = response.data.isAvailable == 1 ? true : false;
          this.price = response.data.price;
          this.discount_id =
            response.data.discount_id != null ? response.data.id : "";
          this.discount_price =
            response.data.discount_price != null
              ? response.data.discount_price
              : "";
          this.weight = response.data.weight;
          this.height = response.data.height;
          this.width = response.data.width;
          this.dimension = response.data.dimension;
          this.discount_name =
            response.data.dis != null ? response.data.dis.name : "اختر خصم";
          this.is_discount = response.data.discount_id != null ? true : false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.popUp {
  height: 100dvh;
  width: 100dvw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.3);
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 100dvw;
  font-size: 40px;
  font-weight: 700;
}
</style>
