import { createI18n } from "vue-i18n";

// Import translation files
import en from "@/locales/en.json";
import ar from "@/locales/ar.json";

// Create i18n instance
const i18n = createI18n({
  locale: "ar", // default locale
  fallbackLocale: "ar", // fallback locale
  messages: {
    en,
    ar,
  },
});

export default i18n;
