<template>
  <div class="news-card">
    <div class="my-product-card">
      <div class="img-container">
        <img :src="$store.state.server_images + img" alt="" />
      </div>
      <div class="my-card-body">
        <div class="news-title text-center">
          {{ title }}
        </div>
      </div>
      <div class="card-footer">
        <router-link :to="'/news-info?id=' + id" class="read-more w-100">
          <div class="mx-1">{{ $t("KnowMore") }}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "news-cards",
  props: ["img", "title", "id", "text"],
};
</script>

<style scoped>
.my-product-card {
  position: relative;
  background-color: white;
  padding: 10px;
  width: fit-content;
  height: 300px;
  transition: 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.my-product-card:hover {
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  transform: translateY(-20px);
  background-color: #072d2a;
}

.img-container {
  height: fit-content;
  width: fit-content;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.img-container > img {
  height: fit-content;
  width: 250px;
  border: 1px solid rgba(100, 100, 100, 0.3);
}

.my-card-body {
  width: 80%;
  margin-right: 20px;
}

.news-title {
  color: #c39a3e;
  font-size: 30px;
}

.news-body {
  font-size: 18px;
}

.card-footer {
  width: 60%;
  margin-right: 20px;
}

.read-more {
  text-decoration: none;
  background-color: unset;
  padding: 10px 20px 10px 20px;
  border: 1px solid rgba(195, 155, 62, 0.5);
  color: #808080;
  transition: 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.read-more:hover {
  transform: translateY(-10px);
  background-color: #c39a3e;
  color: whitesmoke;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}
</style>
