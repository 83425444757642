<template>
  <head>
    <title>{{ $t("Navbar.ContactUs") }}</title>
  </head>
  <div class="register-view">
    <div class="container mt-5 mb-3">
      <div class="row w-100">
        <div class="col-lg-6 flex-center">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2075.961072013957!2d39.231108974862366!3d21.4973579152738!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDI5JzUwLjYiTiAzOcKwMTMnNTEuMSJF!5e0!3m2!1sen!2s!4v1737717476126!5m2!1sen!2s"
            :width="ScreenWidth < 700 ? 300 : 600"
            height="450"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div class="col-lg-6 flex-center">
          <form class="form" v-on:submit.prevent="register()">
            <p class="title">{{ $t("Navbar.ContactUs") }}</p>
            <!-- <p class="message">اشترك للحصول على صلاحيات وميزات حصرية</p> -->
            <div class="flex">
              <label>
                <input
                  required=""
                  type="text"
                  class="input"
                  placeholder=""
                  v-model="Fname"
                />
                <span
                  :style="{
                    right: $i18n.locale === 'ar' ? '10px' : '',
                    left: $i18n.locale === 'ar' ? '' : '10px',
                  }"
                  >{{ $t("ContactUs.fname") }}</span
                >
              </label>

              <label>
                <input
                  required=""
                  placeholder=""
                  type="text"
                  class="input"
                  v-model="Lname"
                />
                <span
                  :style="{
                    right: $i18n.locale === 'ar' ? '10px' : '',
                    left: $i18n.locale === 'ar' ? '' : '10px',
                  }"
                  >{{ $t("ContactUs.lname") }}</span
                >
              </label>
            </div>

            <label>
              <input
                required=""
                placeholder=""
                type="text"
                class="input"
                v-model="phone"
              />
              <span
                :style="{
                  right: $i18n.locale === 'ar' ? '10px' : '',
                  left: $i18n.locale === 'ar' ? '' : '10px',
                }"
                >{{ $t("ContactUs.phone") }}+966-xxx-xxx-xxx</span
              >
            </label>

            <label>
              <input
                required=""
                placeholder=""
                type="email"
                class="input"
                v-model="email"
              />
              <span
                :style="{
                  right: $i18n.locale === 'ar' ? '10px' : '',
                  left: $i18n.locale === 'ar' ? '' : '10px',
                }"
                >{{ $t("ContactUs.email") }}</span
              >
            </label>

            <label>
              <textarea
                required=""
                placeholder=""
                class="input"
                v-model="message"
              ></textarea>
              <span
                style="top: 50px"
                :style="{
                  right: $i18n.locale === 'ar' ? '10px' : '',
                  left: $i18n.locale === 'ar' ? '' : '10px',
                }"
                >{{ $t("ContactUs.message") }}</span
              >
            </label>

            <!-- <label>
          <input
            required=""
            placeholder=""
            :type="inp_type"
            v-model="password"
            class="input"
          />
          <span>كلمة المرور</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            class="eye"
            @click="show_hide_password()"
          >
            <path fill="currentColor" :d="path" />
          </svg>
        </label>

        <label>
          <input
            required=""
            placeholder=""
            :type="inp_type2"
            class="input"
            v-model="confirmPasswword"
          />
          <span>تأكيد كلمة المرور</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            class="eye"
            @click="show_hide_password2()"
          >
            <path fill="currentColor" :d="path2" />
          </svg>
        </label> -->
            <button class="submit">{{ $t("ContactUs.send") }}</button>
            <!-- <p class="signin">
          لديك حساب بالفعل؟
          <router-link to="/login"> سجل الدخول </router-link>
        </p> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import axios from "axios";
import store from "@/store";
export default {
  name: "register-view",
  data() {
    return {
      // inp_type: "password",
      // inp_type2: "password",
      Fname: "",
      Lname: "",
      phone: "",
      email: "",
      message: "",
      ScreenWidth: screen.width,
      // password: "",
      // confirmPasswword: "",
      // path: "M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5",
      // path2:
      //   "M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5",
    };
  },
  methods: {
    // show_hide_password() {
    //   if (this.inp_type == "password") {
    //     this.inp_type = "text";
    //     this.path =
    //       "M11.83 9L15 12.16V12a3 3 0 0 0-3-3zm-4.3.8l1.55 1.55c-.05.21-.08.42-.08.65a3 3 0 0 0 3 3c.22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53a5 5 0 0 1-5-5c0-.79.2-1.53.53-2.2M2 4.27l2.28 2.28l.45.45C3.08 8.3 1.78 10 1 12c1.73 4.39 6 7.5 11 7.5c1.55 0 3.03-.3 4.38-.84l.43.42L19.73 22L21 20.73L3.27 3M12 7a5 5 0 0 1 5 5c0 .64-.13 1.26-.36 1.82l2.93 2.93c1.5-1.25 2.7-2.89 3.43-4.75c-1.73-4.39-6-7.5-11-7.5c-1.4 0-2.74.25-4 .7l2.17 2.15C10.74 7.13 11.35 7 12 7";
    //   } else {
    //     this.inp_type = "password";
    //     this.path =
    //       "M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5";
    //   }
    // },
    // show_hide_password2() {
    //   if (this.inp_type2 == "password") {
    //     this.inp_type2 = "text";
    //     this.path2 =
    //       "M11.83 9L15 12.16V12a3 3 0 0 0-3-3zm-4.3.8l1.55 1.55c-.05.21-.08.42-.08.65a3 3 0 0 0 3 3c.22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53a5 5 0 0 1-5-5c0-.79.2-1.53.53-2.2M2 4.27l2.28 2.28l.45.45C3.08 8.3 1.78 10 1 12c1.73 4.39 6 7.5 11 7.5c1.55 0 3.03-.3 4.38-.84l.43.42L19.73 22L21 20.73L3.27 3M12 7a5 5 0 0 1 5 5c0 .64-.13 1.26-.36 1.82l2.93 2.93c1.5-1.25 2.7-2.89 3.43-4.75c-1.73-4.39-6-7.5-11-7.5c-1.4 0-2.74.25-4 .7l2.17 2.15C10.74 7.13 11.35 7 12 7";
    //   } else {
    //     this.inp_type2 = "password";
    //     this.path2 =
    //       "M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5";
    //   }
    // },
    register() {
      if (this.Fname == "") {
        alert("يجب عليك إدخال الاسم الأول");
        return;
      } else if (this.Lname == "") {
        alert("يجب عليك إدخال الاسم الأخير");
        return;
      } else if (this.phone == "") {
        alert("يجب عليك إدخال رقم الهاتف");
        return;
      } else if (this.phone.search(/^[+][1-9]{1,4}[- 0-9]{3,12}$/) < 0) {
        alert("هذا ليس رقم هاتف");
        return;
      } else if (this.email == "") {
        alert("يجب عليك إدخال الإيميل");
        return;
      } else if (this.email.search(/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/) < 0) {
        alert("هذا ليس إيميل");
        return;
      } else if (this.message == "") {
        alert("يجب عليك إدخال رسالة");
        return;
      }
      //  else if (this.password == "") {
      //   alert("يجب عليك إدخال كلمة المرور");
      //   return;
      // } else if (this.confirmPasswword == "") {
      //   alert("يجب عليك تأكيد كلمة المرور");
      //   return;
      // } else if (this.password.length < 8) {
      //   alert("كلمة المرور مكونة من 8 محارف على الأقل");
      //   return;
      // } else if (this.confirmPasswword.length < 8) {
      //   alert("كلمة المرور مكونة من 8 محارف على الأقل");
      //   return;
      // } else if (this.password != this.confirmPasswword) {
      //   alert("كلمة المرور غير متطابقة");
      //   return;
      // }

      axios(store.state.server + "register", {
        method: "POST",
        data: {
          fname: this.Fname,
          lname: this.Lname,
          email: this.email,
          password: this.password,
          phone: this.phone,
          role: "user",
        },
      })
        .then((response) => {
          alert(response.data.message);
          router.push("/login");
        })
        .catch((error) => {
          if (error) {
            alert("حدث خطأ ما الرجاء المحاولة لاحقاً");
          }
        });
    },
    hideLoadingScreen() {
      store.commit("hideLoadingScreen");
    },
  },
  directives: {
    inView: {
      mounted(el, binding) {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                el.classList.add("animate__animated", binding.value);
                el.classList.remove("hidden");
                observer.unobserve(el); // Stop observing
              }
            });
          },
          { threshold: 0.1 } // Trigger when 10% of the element is visible
        );
        observer.observe(el);
      },
    },
  },
  mounted() {
    this.hideLoadingScreen();
  },
};
</script>

<style scoped lang="scss">
.eye {
  position: absolute;
  top: 14px;
  bottom: 0;
  left: 10px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

/* From Uiverse.io by Yaya12085 */
.form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 10px;
  max-width: 400px;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  position: relative;
}

.title {
  font-size: 28px;
  color: #072d2a;
  font-weight: 600;
  letter-spacing: -1px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 30px;
}

.title::before,
.title::after {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  border-radius: 50%;
  right: 0px;
  background-color: #072d2a;
}

.title::before {
  width: 18px;
  height: 18px;
  background-color: #072d2a;
}

.title::after {
  width: 18px;
  height: 18px;
  -webkit-animation: pulse 1s linear infinite;
  animation: pulse 1s linear infinite;
}

.message,
.signin {
  color: rgba(88, 87, 87, 0.822);
  font-size: 14px;
}

.signin {
  text-align: center;
}

.signin a {
  color: #072d2a;
  text-decoration: none;
}

.signin a:hover {
  -webkit-text-decoration: underline #072d2a;
  text-decoration: underline #072d2a;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  gap: 6px;
}

.form label {
  position: relative;
}

.form label .input {
  width: 100%;
  padding: 10px 10px 20px 10px;
  outline: 0;
  border: 1px solid rgba(105, 105, 105, 0.397);
  border-radius: 10px;
}

.form label .input + span {
  position: absolute;
  top: 15px;
  color: grey;
  font-size: 0.9em;
  cursor: text;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.form label .input:-moz-placeholder-shown + span {
  top: 15px;
  font-size: 0.9em;
}

.form label .input:-ms-input-placeholder + span {
  top: 15px;
  font-size: 0.9em;
}

.form label .input:placeholder-shown + span {
  top: 15px;
  font-size: 0.9em;
}

.form label .input:focus + span,
.form label .input:valid + span {
  top: 30px;
  font-size: 0.7em;
  font-weight: 600;
}

.form label .input:valid + span {
  color: green;
}

.submit {
  border: none;
  outline: none;
  background-color: #072d2a;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  -webkit-transform: 0.3s ease;
  -ms-transform: 0.3s ease;
  transform: 0.3s ease;
}

.submit:hover {
  background-color: #052320;
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 1;
  }

  to {
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
    opacity: 0;
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 1;
  }

  to {
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
    opacity: 0;
  }
}
</style>
